.password-strength-meter {
  text-align: left;
  padding-bottom: 20px;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 250px;
  height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
  font-size: 14px;
  padding-left: 10px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  /*background-color: #F25F5C;*/
  background-color: rgb(255, 0, 0);
}
.strength-Fair::-webkit-progress-value {
  /*background-color: #FFE066;*/
  background-color: rgb(255, 255, 0);
}
.strength-Good::-webkit-progress-value {
  /*background-color: #247BA0;*/
  background-color: rgb(173, 255, 47);
}
.strength-Strong::-webkit-progress-value {
  /*background-color: #70C1B3;*/
  background-color: rgb(0, 128, 0);
}

.font-Very {
  background-color: #eee;
  color: #000;
  padding: 3px 10px;
  border-radius: 10px;
}
.font-Weak {
  background-color: rgb(255, 0, 0);
  color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
}
.font-Fair {
  background-color: rgb(255, 255, 0);
  color: #000;
  padding: 3px 10px;
  border-radius: 10px;
}
.font-Good {
  background-color: rgb(173, 255, 47);
  color: #000;
  padding: 3px 10px;
  border-radius: 10px;
}
.font-Strong {
  background-color: rgb(0, 128, 0);
  color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
}