.afc/*, .afcContact*/{
  /*background-image: none;
  background-color: white !important;*/
}

/*div.afcContact.jumbotron > div > div.text-center.row > div > h2 {
	color: #000;
}*/

p {
  line-height: 1.6;
}

#root > div > footer.afc{
  background-color: transparent !important;
}

div.afc.jumbotron > div > div.text-center.row > div > h2{
  padding: 20px 0 0;
  color: #292b2c;
}

p.topFont{
    font-size: 18px;
}
p.botFont {
    font-size: 13px;
}

/*** Contact Us and FAQ ***/
div.afc.jumbotron > div{
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 1% 2%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1),
div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(2){
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: 40%;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1){
  color: #a81d36;
  font-size: 28px;
  word-wrap: break-word;
}
div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1) > span.phone{
  color: #000;
}
div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(2){
  color: #0275d8;
  font-size: 28px;
  word-wrap: break-word;
}
div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(2) > span.email > a{
  color: #000;
}
div.afc.jumbotron > div > div > div > p > span{
  padding-left: 20px;
}

/*** Mobile View ***/
@media only screen and (orientation:portrait) and (min-width: 375px) {
  div.afcContact.jumbotron > div > div.contactUs.row{
    display: inline-table;
  }
  div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1),
  div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(2){
    font-size: 25px;
    max-width: 100%;
    padding: 15px;
    left: 20px;
  }
  #root > div > div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1) > span.phone{
  	display: none;
  }
  #root > div > div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1) > span.callPhone{
  	display: inline-block;
  }
  #root > div > div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1) > span.callPhone > a{
  	color: #000;
  }
}

@media only screen and (orientation:portrait) and (min-width: 300px) {
  div.afcContact.jumbotron > div > div.contactUs.row{
    display: inline-table;
  }
  div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1),
  div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(2){
  	font-size: 25px;
    max-width: 100%;
    padding: 15px;
    left: 20px;
  }
  #root > div > div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1) > span.phone{
  	display: none;
  }
  #root > div > div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1) > span.callPhone{
  	display: inline-block;
  }
  #root > div > div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1) > span.callPhone > a{
  	color: #000;
  }
}

/*@supports (-ms-accelerator:true) {
  /* IE Edge 12+ CSS styles go here */ 
  /*div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1),
  div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(2){
    background: rgba(240,240,240,0.8991);
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    max-width: 40%;
    padding: 5%;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
  }
}*/
