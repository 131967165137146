/*** Global ***/
body {
  /*background-image: url("../../images/pexels-pixabay-356043.jpg");*/
  
  background-image:url("../../images/AdobeStock_82254181.jpg");


  /*margin-bottom: -1rem !important;*/
  background-repeat: no-repeat;

  /*** bridge ***/
  background-attachment: fixed;
  /*background-position: center center;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;*/
  
  /*** full screen ****/
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.jumbotron {
  min-height: 80%;

  /* white background */
  background-color: transparent !important;
  
  /* background */
  /*background-color: #249ECC !important;
  margin-bottom: -1rem !important;*/

  /* background image */
  /*background-image: url("../../images/header.jpg");
  margin-bottom: -1rem !important;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  text-shadow: black 0.3em 0.3em 0.3em;*/

  border-radius: 0 !important;
}

/*** iAgree landing page header with ReactStrap 5 ***/
#root > div > div.Header > div > nav
{
  display: -webkit-inline-box;
  left: 5%;
}

#root > div > div.Header > div > nav > div
{
  margin-left: 550px;
}

/*** iAgree portal page header with ReactStrap 5 ***/
#root > div > div.HeaderAdmin > div > nav
{
  display: -webkit-inline-box;
}
#root > div > div.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown > a
{
  margin-left: 550px;
}

#root > div > div.HeaderAdmin > div > nav > div
{
  margin-left: 150px;
}
#root > div > div.HeaderAdmin > div > nav > div > ul > ul > li > a
{
  color: #fff;
  font-size: 18px;
  margin-left: 15px;
}
#root > div > div.HeaderAdmin > div > nav > div > ul > ul > li > a:hover
{
  color: rgba(255,255,255,.5);
}

/*** iAGREE login ***/
#root > div > div.jumbotron > div > div > div > p.subtxt
{
  /*background: rgba(0, 0, 0, 0.5);
  border-radius: 10px !important;*/
  font-size: 16px;
  padding: 10px;
  color: #fff;
}

#root > div > div.jumbotron > div > div:nth-child(1) > div.txtS {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px !important;
  font-size: 22px;
  margin-left: -80px;
}

#root > div > div.jumbotron > div > div:nth-child(1) > div > h1.subh1txt{
  /*background: rgba(0, 0, 0, 0.5);
  border-radius: 10px !important;*/
  font: 20px !important;
  padding: 10px 0px;
  color: #fff;
}

#root > div > div.jumbotron > div > div:nth-child(1) > div.subtxt{
  /*background: rgba(0, 0, 0, 0.5);
  border-radius: 10px !important;*/
  font-size: 14px;
  padding: 10px 0px;
  color: #fff;
}

#root > div > div.jumbotron > div > div:nth-child(1) > div > hr.homeHR{
  max-width: 50px;
  border-width: 3px;
  border-color: #f05f04;
  margin: 30px auto;
  paddin: 0px;
}

#root > div > div.Header > div > nav > a
{
  color: #fff;
  font-weight: 700;
  /*text-transform: uppercase;*/
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

/*** Term ***/
#root > div > div.Terms.jumbotron > div > div.text-center.row > div > h1, 
#root > div > div.Privacy.jumbotron > div > div.text-center.row > div > h1{
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px !important;
  padding: 15px 0px;
}

/*** About & faq ***/
#root > div > div.afc.jumbotron > div
{
  background: #fff !important;;
}

#root > div > div.jumbotron > div > div:nth-child(3) > div > p > a
{
  color: #fff;
}

.col-sm-4 > form:nth-child(1) 
{
  background: #fff;
  padding: 30px 25px;
  border-radius: 20px;
}

html body div#root div.rootPage div.jumbotron div.container div.row div.col.col-sm-4.push-sm-4 form p.text-center.orP,
html body.modal-open div div div.modal.fade.show div.modal-dialog div.modal-content div.providerMBody.modal-body span div.container div.row div.col div.col form div.text-center.row div.col.col-sm-8.push-sm-2 p 
{
  color: #099a09;
  margin-bottom: 1em;
  text-transform: uppercase;
}

html body div#root div.rootPage div.jumbotron div.container div.row div.col a.social-icon.googleIcon
{
  margin-right: 20px;
  cursor: pointer;
}

html body div#root div.rootPage div.jumbotron div.container div.row div.col a.social-icon.fbIcon
{
  cursor: pointer;
}

html body div#root div.rootPage div.jumbotron div.container div.row div.col a.social-icon.twitterIcon
{
  margin-left: 20px;
}

html body div#root div.rootPage div.jumbotron div.container div.row div.col.col-sm-4.push-sm-1 p.text-center.csTxt,
#root > div > div.jumbotron > div > div:nth-child(4) > div > p.csTxt > a,
#root > div > div.jumbotron > div > div:nth-child(4) > div > p.csTxt
{
  color: #fff;
  padding-top: 10px;
}

.margin10
{
  margin-top: 10px;
}
/*** Header ***/
.divRight {
  float: right;
}

.Headar .bg-fade{
  background: transparent !important;
}

#root > div > div.jumbotron > div > div > div > p:nth-child(5) > a.btn.btn-success.btn-lg{
  vertical-align: bottom;
}
#root > div > div.jumbotron > div > div > div > p:nth-child(5) > a.btn.btn-warning.btn-lg{
  vertical-align: bottom;  
}

.Header a.dropdown-toggle:hover {
	color: #292b2c;
	border: 1px solid #292b2c;
	margin: -1px;
}

.Header .dropdown-menu > a {
	color: inherit;
}
.Header .progressBar > li {
  padding: 0.5em 1em;
} 
.Header .progressBar li > a {
  text-decoration: none;
  font-size: 1em;
}

#root > div > div.Header > div > nav > a > img{
  /*margin-left: -20px;*/
}

#root > div > div.Header > div > nav > div > ul > li > a{
  color: #fff;
  font-weight: 700;
  /*text-transform: uppercase;*/
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 20px;
}

#root > div > div.Header > div > nav > div > ul > li > a:hover{
  color: wheat;
}

.jumbotron > div > div:nth-child(1) > div > h1.homeHR {
  /* title */
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 50px;
}

#root > div > div.jumbotron > div > hr{
  max-width: 50px;
  border-width: 3px;
  border-color: #f05f04;
  margin: 30px auto;
}

#root > div > div.jumbotron > div > div.text-center.row > div {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 20px;
}

#root > div > div.jumbotron > div > div:nth-child(4) > div > a:nth-child(1) > i,
#root > div > div.jumbotron > div > div:nth-child(4) > div > a:nth-child(2) > i{
  margin-bottom: 5px;
}

#root > div > div.jumbotron > div > div:nth-child(4) > div > a:nth-child(1){
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
  border-radius: 80px !important;
  font-size: 15px;
  width: 250px;
  padding: 15px;
  margin: 40px 20px 0 0;
  color: #fff;
  border-color: #244bcc;
  background-color: #244bcc;
  text-transform: uppercase;
}
#root > div > div.jumbotron > div > div:nth-child(4) > div > a:nth-child(2){
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
  border-radius: 80px !important;
  font-size: 15px;
  width: 250px;
  padding: 15px;
  margin: 40px 20px 0 0;
  color: #fff;
  border-color: #f05f04;
  background-color: #f05f04;
  text-transform: uppercase;
}

#root > div > div.jumbotron > div > div:nth-child(4) > div > a:nth-child(1):hover{
  color: #fff;
  border-color: #2426cc;
  background-color: #2426cc;
}
#root > div > div.jumbotron > div > div:nth-child(4) > div > a:nth-child(2):hover{
  color: #fff;
  border-color: #e13612;
  background-color: #eb3812
}

/*** Pupup logged out ***/
body > div:nth-child(3) > div > div.modal.fade.show > div > div
{
  padding: 20px;
}

.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > button{
  font-family: inherit;
  line-height: 35px;
  font-size: 17px;
}

.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:nth-child(1),
.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:nth-child(2),
.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:nth-child(3),
.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:nth-child(4){
  color: #007bff;
  padding: 10px 1.5rem;
}

.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:nth-child(1):focus,
.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:nth-child(2):focus,
.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:nth-child(3):focus,
.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:nth-child(4):focus{
  color: white;
}

.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > button:hover,
.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > button a:hover{
  background-color: none;
  text-decoration: none;
}

/*** Home (Paypal -> https://www.paypal.com/us/webapps/mpp/account-selection) 
     Sign in (Zillow ->https://www.zillow.com/) ***/
#root > div > div.Provider-SelectFacility > div > div > div{
  border-radius: 10px !important;
  width: 50%;
  padding: 50px;
  /*margin-right: auto;
  margin-left: auto;*/
}
#root > div > div.Provider-SelectFacility > div > div > div > h4{
  font-size: 38px;
  margin-bottom: 65px;
}
#root > div > div.Provider-SelectFacility > div > div > div > h6:nth-child(2) > input,
#root > div > div.Provider-SelectFacility > div > div > div > h6:nth-child(3) > input {
  width: 1em;
  height: 1em;
}

#root > div > div.Provider-SelectFacility > div > div > div > h6:nth-child(2),
#root > div > div.Provider-SelectFacility > div > div > div > h6:nth-child(3){
  font-size: 25px;
  margin: 25px 0;
}

/*** Patient button ***/
/* *, *::before, *::after{
  box-sizing: content-box !important;
}*/
#root > div > div.jumbotron > div > div > div > div{
  /*background: rgba(240,240,240,0.8991);
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  padding: 5% 1%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);*/
}
#root > div > div.jumbotron > div > div > div > div > div > button:nth-child(1){
  font-family: pp-sans-small-regular,Helvetica Neue,Arial,sans-serif;
  color: #fff !important;
  font-weight: 500;
  margin-top: 200px; 
  font-size: 1.5rem;
  /*font-size: 1.07142857rem;
  line-height: 1.5;
  padding: 20px 10px;
  width: 250px;
  height: 100px;*/
  word-spacing: 4px;
  border-radius: 40px;
  background-color: #d9534f !important;
}

/*#root > div > div.jumbotron > div > div > div > div > div > button:nth-child(1):hover{*/
#root > div > div.jumbotron > div > div > div > div > div > button:nth-child(1){
  cursor: pointer;
}
.btn-outline-danger{
  background-color: #fff !important;
}
.btn-outline-danger:hover{
  background-color: #d9534f !important;
}

/*** https://codepen.io/Bouhtouch/pen/DHALw ***/
* > .fa.fa-hover-show,
*:hover > .fa.fa-hover-hidden {
  display: none;
}
*:hover > .fa.fa-hover-show {
  display: inline-block;
}

/*** https://codepen.io/Bouhtouch/pen/DHALw ***/
body > div:nth-child(7) > div > div.modal.fade.show > div > div > div.modal-footer > h6{
  display: block;
}

#root > div > div.MFA > div > div > div:nth-child(2) > div > div{
  margin-left: 20px;
}

/*** Provider button ***/
#root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2){
  font-family: pp-sans-small-regular,Helvetica Neue,Arial,sans-serif;
  font-weight: 700;
  margin-top: 10px; 
  font-size: 1.07142857rem;
  line-height: 1.5;
  margin-top: 10px; 
  margin-left: 15px;
  padding: 20px 10px;
  width: 250px;
  height: 100px;
  word-spacing: 4px;
  border-radius: 40px;
}
#root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2):hover {
  margin-top: 10px; 
  padding: 20px 10px;
  cursor: pointer;
}

#root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2):after {
  max-width: 100%;
}
#root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2) > i.fa.fa-circle-o.fa-2x{
  margin-left: -20px;
  padding-right: 10px;
}
.btn-outline-primary{
  background-color: #fff !important;
}
.btn-outline-primary:hover{
  background-color: #0275d8 !important;
}

/*@media only screen and (orientation:landscape) and (min-width: 414px) {
    #root > div > div.jumbotron > div > div > div > div > p > button:nth-child(2) {
      width: 60px;
      height: 20px;
    }
}*/

/*** hanburger menu ***/
.navbar-light .navbar-toggler {
    background-color: rgba(20,20,20,0.4);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/*** Home Popup Modal ***/
/*.modal-dialog {
  position: absolute;
  top: 40%;
  left: 13%;
  transform: translate(-50%, -50%) !important;
}*/

/*.modal.fade.show > div > div{
  background-color: rgba(0, 0, 0, 0.21);
  background-color: rgba(255, 255, 255, 0.48);
  background: transparent !important;  
}*/

.modal.fade.show > div > div > div.modal-header{
  border-bottom: none;
  border-radius: 10px;
  /*margin-left: auto;
  margin-right: auto;*/
}
div.modal.fade.show > div > div {
  border-radius: 10px;
  /*position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;*/
}


.modal.fade.show > div > div > div.providerMHead.modal-header{
  background-color: #f7f8fa;
  color: #005ba4;
  padding: 25px 30px 25px 20%;
}
.modal.fade.show > div > div > div.providerMBody.modal-body{
  padding: 30px 28px 20px;
}
.providerMBody.modal-body > form > div > div,
.providerMBody.modal-body > form > div > input{
  font-family: 'Lato', Calibri, Arial, sans-serif;
  /*background-color: #f9f9f9;
  text-shadow: 0 1px 0 rgba(255,255,255,0.8);*/
  box-shadow: inset 0 0 5px rgba(0,0,0,0.1), inset 0 3px 2px rgba(0,0,0,0.1);
}
.providerMBody.modal-body input::-webkit-input-placeholder{
  /* WebKit browsers */
  color: #999;
}
.providerMBody.modal-body input:-moz-placeholde{
  /* Mozilla Firefox 4 to 18 */ 
  color: #999;
}
.providerMBody.modal-body input::-moz-placeholder{ 
  /* Mozilla Firefox 19+ */
  color: #999;
}
.providerMBody.modal-body input:-ms-input-placeholder{
  /* Internet Explorer 10+ */
  color: #999;
}

.modal.fade.show > div > div > div.providerMFooter.modal-footer{
  border-top: none;
  padding: 0px 25px 40px;
}

.modal.fade.show > div > div > div.patientMHead.modal-header{
  background-color: #f7f8fa;
  color: #005ba4;
  padding: 25px 30px 25px 20%;
}
.modal.fade.show > div > div > div.patientMFooter.modal-footer{
  border-top: none;
}

/*.tab-trigger-block:before {
    display: block;
    content: '';
    width: 32px;
    height: 32px;
    background: #fff;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 20px;
    border: 2px solid;
    border-radius: 50%;
}*/

/*** Security ***/
div.facilityMFA > div > div > div:nth-child(2) > div > div:nth-child(n){
  margin-left: 10px;
}

#root > div > div.facilityMFA > div > div > div:nth-child(2) > div > div:nth-child(1) > div:nth-child(n+3),
#root > div > div.facilityMFA > div > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(n+3)
{
  text-indent: 15px;
}

/*** MFA popup ***/
#mfaverify{
  margin-left: -20%;
}

/*** ProviderMainMenu ***/
span.noteCR{
	margin-left :  3em ;
}
.imgCR{
    vertical-align: top;
    height: 20px;
    width: 20px;
}
.imgCR1{
    vertical-align: top;
    height: 20px;
    width: 20px;
}

.AccountHome .newUser{
    padding-bottom: 10px;
    color: #0275d8;
}

/*** Profile ***/
.Profile h3:not(:first-child){
	padding-top: 40px;
}
.Profile .my-0 {
	padding: 5px 0;
}

/*** AddFacility ***/
.AddFacility h6{
	text-align: center;
}
.AddFacility .card{
	/*padding: 15px;*/
	padding-bottom: 10px;
	margin-bottom: 20px;
	text-align: center;
}
.AddFacility .btn{
	width: 100px;
    margin: 0 auto;
	text-align: center;
	padding: 10px;
}
.AddFacility .card-header{
	height: 100%;
	width: 100%;
}
.AddFacility .card-text{
	padding: 8px;
}

/*** Profile ***/
.Profile .card{
	margin-top: 20px;
}

.Profile .card .btn{
	width: 120px;
	padding-left: 10px;
}

/*** AddProviderLogin ***/
.providerMBody.modal-body > span > div > div > div > div > form > button > i{
  padding-right: 10px;
}

.AddProviderLogin form, .AddProviderLogin h4{
	padding: 30px;
}

/*** CloseAccount ***/
.CloseAccount input.formCheckbox{
	margin: 15px 0;
}

/*** Footer ***/
.footer{
  flex: none;
  background: transparent !important;
  color: #fff;
  /*padding: 0 20px 0 0;
  font-family: Helvetica,Arial,sans-serif;*/
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: .82857143em;
  font-weight: bold;
  text-shadow: none;
  /*line-height: 2em;
  -webkit-font-smoothing: antialiased;*/
}
#root > div > footer > div > span > a{
  background: transparent !important;
  color: #fff;
  padding-left: 10px;
}
#root > div > footer > div > span > a:hover{
  text-decoration: none;
}

/*** Patient ***/
.Patient h5 {
  text-align: center;
}

.Patient > div > div > div > div > div > div > div > div > button {
  margin-right: auto;
  margin-left: auto;
  width: 30%;
}

.Patient > div > div > div > div > div > div > div.text-center.card > a{
  margin-right: auto;
  margin-left: auto;
  width: 30%;
}
/*#root > div > div.Patient > div > div > div > div > div > hr
{
  border:         none;
  border-left:    1px solid hsla(200, 10%, 50%,100);
  height:         100vh;
  width:          1px;       
}*/
.Patient .card, .Patient .card-text{
  padding: 15px;
  margin-top: 10px;
}

/*** Patient email ***/
.Email input, .Email label{
  cursor:pointer;
}

.Email input[type=radio]:checked + label,
.Email input[type=radio]:checked + input{
  color: #0275d8;
  font-weight: bold;
}

/***  SFLoginForm  ***/
/* shared */
.SFLogin .loginBtn,
.SFCreate .loginBtn{
  box-sizing: border-box;
  position: relative;
  width: 13em;  /* apply for fixed size */
  margin: 1em 0.5em 0.5em 0.5em;
  padding: 1px 15px 1px 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 17px;
  color: #FFF;
}
.SFLogin .loginBtn:before,
.SFCreate .loginBtn:before{
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.SFLogin .loginBtn:focus,
.SFCreate .loginBtn:focus{
  outline: none;
}
.SFLogin .loginBtn:active,
.SFCreate .loginBtn:active{
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.SFLogin .loginBtn--facebook,
.SFCreate .loginBtn--facebook{
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  font-family: "Roboto", Roboto, arial, sans-serif;
  text-shadow: 0 -1px 0 #354C8C;
}
.SFLogin .loginBtn--facebook:before, 
.SFCreate .loginBtn--facebook:before{
  border-right: #364e92 1px solid;
  background: url("../../images/icon_facebook.png") 6px 6px no-repeat;
}
.SFLogin .loginBtn--facebook:hover,
.SFLogin .loginBtn--facebook:focus,
.SFCreate .loginBtn--facebook:hover,
.SFCreate .loginBtn--facebook:focus{
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.SFLogin .loginBtn--google,
.SFCreate .loginBtn--google{
  font-family: "Roboto", Roboto, arial, sans-serif;
  background: #DD4B39;
}
.SFLogin .loginBtn--google:before, 
.SFCreate .loginBtn--google:before{
  border-right: #BB3F30 1px solid;
  background: url("../../images/icon_google.png") 6px 6px no-repeat;
}
.SFLogin .loginBtn--google:hover,
.SFLogin .loginBtn--google:focus,
.SFCreate .loginBtn--google:hover,
.SFCreate .loginBtn--google:focus{
  background: #E74B37;
}

.SFLogin .Line,
.SFCreate .Line{
  border-left: 1px solid rgba(0, 0, 0, .1);
  height: auto;
}

.SFLogin .Or{
  /*display: block;
  position: absolute;*/
  left: 50%;
  top: -0.6em;
  margin-left: -1.5em;
  color: rgba(0, 0, 0, .1);
  width: 3em;
  text-align: center;
}


/***  General for social icon  ***/
/* shared */
.loginBtn,
.loginBtn{
  box-sizing: border-box;
  position: relative;
  width: 13em;  /* apply for fixed size */
  /*margin: 1em 0.5em 0.5em 0.5em;*/
  margin-right: 10px;
  margin-top: 10px;
  padding: 1px 15px 1px 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 19px;
  color: #FFF;
}
.loginBtn:before,
.loginBtn:before{
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus,
.loginBtn:focus{
  outline: none;
}
.loginBtn:active,
.loginBtn:active{
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.loginBtn--facebook,
.loginBtn--facebook{
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  font-family: "Roboto", Roboto, arial, sans-serif;
  text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before, 
.loginBtn--facebook:before{
  border-right: #364e92 1px solid;
  background: url("../../images/icon_facebook.png") 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus,
.loginBtn--facebook:hover,
.loginBtn--facebook:focus{
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.loginBtn--google,
.loginBtn--google{
  font-family: "Roboto", Roboto, arial, sans-serif;
  background: #DD4B39;
}
.loginBtn--google:before, 
.loginBtn--google:before{
  border-right: #BB3F30 1px solid;
  background: url("../../images/icon_google.png") 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus,
.loginBtn--google:hover,
.loginBtn--google:focus{
  background: #E74B37;
}

.Line,
.Line{
  border-left: 1px solid rgba(0, 0, 0, .1);
  height: auto;
}

.Or{
  /*display: block;
  position: absolute;*/
  left: 50%;
  top: -0.6em;
  margin-left: -1.5em;
  color: rgba(0, 0, 0, .1);
  width: 3em;
  text-align: center;
}

.Privacy.jumbotron > div > div:nth-child(2) > div{
  background-color: white;
  max-width: 98%;
  margin-left: auto;
  margin-right: auto;  
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 3px;
  max-height: 32em;
}

.Terms.jumbotron > div > div:nth-child(2) > div{
  background-color: white;
  max-width: 98%;
  margin-left: auto;
  margin-right: auto;  
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 3px;
  max-height: 32em;
}

/*** FacilityMFAForm***/
.facilityMFA > div > div {
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
.facilityMFA > div > div > div:nth-child(1) > div > span > h1 {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 15px;
}
/*.facilityMFA > div > div > div > div > span > hr {
    max-width: 300px;
    border-width: 3px;
    border-color: #f05f04;
    margin: 30px auto;
}*/
.facilityMFA > div > div > div > div > span > h4 {
  font-size: 18px;
  font-weight: 700;
  padding: 15px 0;
}
.facilityMFA > div > div > div:nth-child(2) {
  font-size: 17px;
  font-weight: 500;
}

/*** EstablishProviderMFA ***/
.EstablishMFA > span > div > div {
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
/*#root > div > div.EstablishMFA > div > div.jumbotron > div > h1{
  font-size: 25px;
  font-weight: 700;
}*/

/*** IDVerificationForm ***/
#root div.rootPage div.Email div.EmailForm div.jumbotron div.email1,
#root div.rootPage div.Email div.EmailForm div.jumbotron div.email2
{
    border-radius: 1.25rem;
    padding: 10px;
    border-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}

#root > div > div:nth-child(2) > div > div.jumbotron > div{
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}

#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol1.col,
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol2.col,
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol3.col{
  flex: 0 0 95%;
  max-width: 95%;
  border: 1px solid;
  border-color: #5bc0de;
  border-radius: 5px;
  /*box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);*/
  margin: 10px;
  padding: 5px;
}
.flex-box {
  display:flex;
  justify-content:space-between;
  outline: 2px dashed blue;
}
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol1.col > p,
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol2.col > p,
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol3.col > p{
  /*line-height: 3.4;*/
  margin: 10px 0;
}
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol1.col > p > span,
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol2.col > p > span,
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol3.col > p > span{
  vertical-align: middle;
}
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol1.col > p > span > button,
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol2.col > p > span > button,
#root > div > div:nth-child(2) > div > div.jumbotron > div > div > div.idCol3.col > p > span > button{
  float: right;
}

/*** ProviderEmail ***/
.Email > div > div > div{
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
.EmailForm .card-text{
  padding: 10px 15px;
}
.Email > div > div > div > h6{
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

/*** AccountClosed ***/
#root > div > div.AccountClosed.jumbotron > div{
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}

/*** AccountClosedForm ***/
/*#root > div > div.CloseAccount > div > div > div.AccountClosedForm > div > div*/
div.AccountClosedForm > div > div {
  margin-top: -3%;
  position: fixed;
  float: none;
  cursor: pointer;
}

div.AccountClosedForm > div > div > div > textarea{
  margin-bottom: 20px;
}


div.AccountClosedForm > div > div > div > span > span > input[type="checkbox"]{
  margin-top: 5px;

}

div.AccountClosedForm > div > div > div > span > span > label{
  float: none;
  margin-left: 25px;
  margin-bottom: 20px;
}

/*** LoggedOut ***/
#root > div > div.LoggedOut.jumbotron > div{
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}

#root > div > div.LoggedOut.jumbotron > div > div:nth-child(1) > div > h1{
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  color: #d9534f;
  font-size: 30px;
}
#root > div > div.LoggedOut.jumbotron > div > div.text-center.row > div > h4{
  color: #555;
  font-size: 20px;
}

/*** MFA ***/
#root > div > div.MFA > div > div{
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}

/*** EstablishMFA ***/
#root > div > div.EstablishMFA > div > div.jumbotron > div{
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);

}

/*** selectFacility ***/
#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div{
  /*background: rgba(240,240,240,0.8991);*/
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > h1{
  color: inherit;
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 15px;
}
#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > div > div.facRow{
  text-indent: 10px;
}
#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(2){
  margin-top: 20px;
}
div.slectFacForm.jumbotron #backBtn{
  color: #fff;
  background-color: #b7b7b7;
  border-color: #b7b7b7;
}
div.slectFacForm.jumbotron #backBtn:hover{
  color: #fff;
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

/*** Patient Identity Verification Step 2/4 ***/
#root > div > div:nth-child(2) > div > div.idVForm.jumbotron > div > div > div:nth-child(1) > h1,
#root > div > div:nth-child(2) > div > div.IDVCode.jumbotron > div > div > div > h1,
#root > div > div:nth-child(2) > div > div.IDMyChart.jumbotron > div > div > div > h1{
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    padding-bottom: inherit;
}
#root > div > div:nth-child(2) > div > div.idVForm.jumbotron > div > div > div:nth-child(1) > h1 > span,
#root > div > div:nth-child(2) > div > div.IDVCode.jumbotron > div > div > div > h1 > span,
#root > div > div:nth-child(2) > div > div.IDMyChart.jumbotron > div > div > div > h1 > span{
    display: inline;
    float: right;   
}
div.idVForm.jumbotron #backBtn{
  margin: 10px 0 -10px -5px;
  color: #fff;
  background-color: #b7b7b7;
  border-color: #b7b7b7;
}
div.idVForm.jumbotron #backBtn:hover{
  color: #fff;
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

/*** Patient Identity Verification Step 3/4 ***/
#root > div > div:nth-child(2) > div > div.idVPinfo.jumbotron > div > div > div > div > div > h1 > span{
    display: inline;
    float: right;   
}
div.idVPinfo.jumbotron .col-sm-5,
#root > div > div:nth-child(2) > div > div.idVPinfo.jumbotron > div > div:nth-child(2) > p{
  margin-left: 20px;
}
div.idVPinfo.jumbotron .col-sm-5 > div{
  padding:20px;
}
div.idVPinfo.jumbotron label:nth-child(n+3){
  margin-top: 20px;
}
#idpSubmit{
  margin-top: 20px;
  float: right
}
.IDVCode .input-group{
  width: 30%;
}
div.IDVCode.jumbotron > div > div > div > form > div:nth-child(1) > div > p{
  margin-bottom: 0.5rem;
}
div.IDVCode.jumbotron > div > div > div > form > div:nth-child(1) > div > div > i:nth-child(2),
div.IDVCode.jumbotron > div > div > div > form > div:nth-child(1) > div > div > i:nth-child(4){
  margin: 3% 2% 0;
}
div.IDVCode.jumbotron #backBtn,
div.IDMyChart.jumbotron #backBtn{
  margin-top: 20px;
  float: left;
  color: #fff;
  background-color: #b7b7b7;
  border-color: #b7b7b7;
}
div.IDVCode.jumbotron #backBtn:hover,
div.IDMyChart.jumbotron #backBtn:hover{
  margin-top: 20px;
  color: #fff;
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

div.IDMyChart.jumbotron > div > div > div > div > div > form > button.btn.btn-primary.btn-lg{
  margin-top: 20px;
  float: right;
}

/*** ForgotPassord ***/
#root > div > div.ForgotPassword.jumbotron > div{
    /*background: rgba(240,240,240,0.8991);*/
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
    padding: 5%;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
#root > div > div.ForgotPassword.jumbotron > div > div > div > h1{
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.1;
    color: #d9534f;
    padding-bottom: inherit;
}
#root > div > div.ForgotPassword.jumbotron > div > div > div > form > fieldset > legend{
    font-style: inherit;  
    font:  red;
    font-size: inherit;
}
#root > div > div.ForgotPassword.jumbotron > div > div > div > form > fieldset > div.form-control.p-0{
    padding: 15px 10px!important;
}
#root > div > div.ForgotPassword.jumbotron > div > div > div > form > fieldset > div.form-control-feedback{
    color: #d9534f !important;
} 
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset {
    width: 80%;
}
#numberSSN{
    width: 10%;
}
#dateDOB {
    width: 20%;
}
/*#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-untouched.is-pristine.av-invalid > div:nth-child(1) > label,
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-touched.is-pristine.av-invalid.is-invalid > div:nth-child(1) > input
{
    float: left;
}
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-untouched.is-pristine.av-invalid > img.CSselect,
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-touched.is-dirty.av-valid > img.CSselect{
    width: 10%;
    margin-top: -15px;
    margin-left: 5px;
}
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-untouched.is-pristine.av-invalid > div:nth-child(3),
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-touched.is-dirty.av-valid > div:nth-child(3){
    padding-top: 10px;
}
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-untouched.is-pristine.av-invalid > div:nth-child(3) > label,
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.s-touched.is-dirty.av-valid > div:nth-child(3) > label{
    float: left;
}
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-untouched.is-pristine.av-invalid > img.CRIselect,
#root > div > div.ForgotPassword.jumbotron > div > div:nth-child(2) > div > form > fieldset > div.form-control.border-0.p-0.h-auto.is-touched.is-dirty.av-valid > img.CRIselect{
    width: 14%;
    margin-left: 10px;
}*?

/*** NewPassord ***/
#root > div > div.NewPassword.jumbotron > div{
    /*background: rgba(240,240,240,0.8991);*/
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
    padding: 5%;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
#root > div > div.NewPassword.jumbotron > div > div > div > h1{
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.1;
    color: #d9534f;
    padding-bottom: inherit;
}
/*#newpassword,
#newpassword2{
    width: 80%;
}*/

/*** OTP ***/
div.otp.jumbotron > div > div > div > h4{
  color: #0275d8;
}
div.otp.jumbotron > div > div:nth-child(2) > div{
  padding: 5px 0 5px 2%;
}
div.otp.jumbotron > div > div > div > button{
  margin-top: 20px;
}

/*** OTPVerify ***/
div.otpVerify.jumbotron > div > div:nth-child(1) > div > h4{
  color: #449d44;
}
div.otpVerify.jumbotron > div > div:nth-child(3) > div > p{
  margin-top: 5%;
}
div.otpVerify.jumbotron #otpverify{
  display: inline;
  width: 50%;
}
div.otpVerify.jumbotron > div > div:nth-child(2){
  margin-top: 5%;
}
div.otpVerify.jumbotron #backBtn{
  margin-top: 20px;
  color: #fff;
  background-color: #b7b7b7;
  border-color: #b7b7b7;
}
div.otpVerify.jumbotron #backBtn:hover{
  color: #fff;
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

/*** Admin Header***/
.HeaderAdmin {
  background-color: #3c92ca;
  font-size: 1em;
  color: #fff;
}
.HeaderAdmin > div > nav > div > ul.progressBar.nav{
  color: #fff;
  margin-top: 10px;
  font-size: 18px;
}
.HeaderAdmin > div > nav > div > ul.progressBar.nav > li > a{
  color: #fff;
  padding: 10px 15px;
}
.HeaderAdmin > div > nav > div > ul.ml-auto.nav.nav-pills > li{
  font-size: 18px;
}
.HeaderAdmin > div > nav > div > ul.ml-auto.nav.nav-pills > li:nth-child(1) > a,
.HeaderAdmin > div > nav > div > ul.ml-auto.nav.nav-pills > li:nth-child(2) > a,
.HeaderAdmin > div > nav > div > ul.ml-auto.nav.nav-pills > li:nth-child(3) > a{
  display: none;
}

.HeaderAdmin .dropdown-menu {
  min-width: 38%;
}

#root > div > div.HeaderAdmin > div > nav > div > ul.ml-auto.nav.nav-pills > li.nav-item.dropdown > a{
  color: #fff;
}

/*** Admin AccountHome***/
#root > div > div.AccountHome{
  background-color: #fff;
  clear: both;
}

.AccountHome h3{
  padding-top: 10px;
}

#root > div > div.AccountHome > div > div > h1 > span:nth-child(1) > span{
  padding-right: 10px;
}



#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div > span > button.CS-Link,
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div > span > button.Cedars {
  margin-top: 10px;
  background-color: #a81d36;
  border-color: #a81d36;
  width: 280px;
}
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div > span > button.CS-Link:hover,
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div > span > button.Cedars:hover{
  background-color: #b94a5e;
}

#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div> span > button.Rehab,
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div> span > button.Cal{
  margin-top: 10px;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  width: 280px;
}
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div > span > button.Rehab:hover,
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div > span > button.Cal:hover{
  background-color: #f3bd71;
}

#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div> span > button.California{
  margin-top: 10px;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  /*width: 280px;*/
}
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div > span > button.California:hover{
  background-color: #f3bd71;
}


#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div> span > button.Party,
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div> span > button.RP{
  margin-top: 10px;
  background-color: #0275d8;
  border-color: #0275d8;
  width: 280px;
}
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div> span > button.Party:hover,
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div> span > button.RP:hover{
  background-color: #1b82db;
}

#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div.nofacility > button{
  background-color: #e3e3e3;
}
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div.nofacility > button:focus{
  box-shadow: none;
}
#root > div > div.AccountHome > div > div > div:nth-child(3) > div > div.nofacility > button:hover{
  border-color: #ccc;
}

/*** Admin Footer ***/
.footerAdmin{
  background-color: #fff;
  color: #555;
  padding-left: 20px
}
#root > div > footer.footerAdmin > div > span > a:nth-child(1),
#root > div > footer.footerAdmin > div > span > a:nth-child(2){
  color: #555;
}

/*** Admin ***/
.AccountHome,
.AddFacility,
.Help,
.Profile,
.CloseAccount{
  background-color: #fff;
  height: 100%;
}

/*** Admin Help ***/
.Help > div > div > div > div > div > div > p:nth-child(8){
  padding-top: 10px;
}
.Help > div > div > div > div > div > div > div > div{
  width: 50%;
}
.Help > div > div > div > div > div > div > div > div > p{
  padding: 20px;
}

/*** Admin AddFacility ***/
.removeFacility {
  padding: 30px;
}

/*** Admin CloseAccount ***/
.CloseAccount > div > div {
  width: 80%;
  padding-left: auto;
  padding-right: auto;
}


/*** addFacilityStep ***/
.addFacilityStep .alert{
  padding: 10px 0;
}

/*** Patient Modal ***/
.patientMBody.modal-body > div:nth-child(5) > div > div > form,
.patientMBody.modal-body > div:nth-child(6) > div > div > form{
  padding: 15px;
}
.patientMBody.modal-body > div:nth-child(5) > div > div{
  margin-bottom: 15px;
}

/*** HeaderAdmin AccountHome ***/
.HeaderAdmin > div > nav > a{
  margin-left: -30px;
}

.HeaderAdmin > div > nav > div > ul > li.nav-item.dropdown.show > div > a:hover{
  text-decoration: none;
}

/*** Modal: Facilities->add/remove; ***/
div.modal.fade.show > div > div > div > div > div > div:nth-child(2) > div > div > form > div:nth-child(2),
div.modal.fade.show > div > div > div > div > div > div:nth-child(2) > div > div > form > div:nth-child(3),
div.modal.fade.show > div > div > div > div > div > div:nth-child(2) > div > div > form > div:nth-child(4),
div.modal.fade.show > div > div > div > div > div > div:nth-child(2) > div > div > form > div:nth-child(5){
  margin-left: 20px;
  padding: 5px;
}
#mfaSubmit, div.modal.fade.show > div > div > div > div > div > div:nth-child(2) > div > div > form > button.btn.btn-secondary.btn-lg{
  margin-top: 20px;
}
div.AddFacility > div > div > h1.addRemoveFacilites{
  padding-bottom: 30px; 
  color: #0275d8; 
}

/*** Modal: Second Factor Authentication->Update Methods ***/
div.modal.fade.show > div > div > div > div > div > div > h6{
  padding: 10px;
  line-height: 20px;
}
#continueBtn{
  /*margin-top: 10px;*/
  margin-left: 10px;
}

/*** Help -> Documentation and Support Materials ***/
div.Help > div > div > div > div > div > div > div{
  margin-top: 10px; 
}
div.Help > div > div > div > div > div > div > h1{
  color: #0275d8; 
}
#root > div > div.afcContact.jumbotron > div > div.contactUs.row > div:nth-child(1) > span.callPhone{
  display: none;
}

/*** for all radio button ***/
input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

/*** for all radio button ***/
input[type="checkbox"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

/*** Responsive mobile ***/
@media only screen and (orientation:portrait) and (min-width: 375px) {
  body{
    /*** iPhone & Mac & Android ****/
    background-position: center left;
    -webkit-background-size: cover cover;
    -moz-background-size: cover cover;
    -o-background-size: cover cover;
    background-size: cover cover;
  }
  #root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2){
    margin-left: 0px;
  }
  #root > div > div.Header > div > nav > button{
    margin-top: 8px;
    border-color: rgba(255, 255, 255, 0.3);
  }
  #root > div > div.Header > div > nav > a > img{
    margin-left: 0;
  }
  #root > div > div.jumbotron > div > div:nth-child(1) > div > h1{
    font-size: 25px;
  }
  #root > div > div.jumbotron > div > div.text-center.row > div > p{
    font-size: 12px;
  }
  #root > div > div.jumbotron > div > div:nth-child(4) > div > div > h4{
    font-size: 1.3rem;
  }
  .Header .nav-item{
    padding-left: 5px;
  }
  #root > div > div.jumbotron > div > div > div > div{
    max-width: 100%;
  }
  #root > div > div.jumbotron > div > div:nth-child(4) > div > div > p > button.btn.btn-outline-danger{
    margin-top: 0;
    padding: 0;
    font-size: inherit;
    width: 220px;
    height: 60px; 
    border-radius: 10px;
  }
  #root > div > div.jumbotron > div > div:nth-child(4) > div > div > p > button.btn.btn-outline-primary{
    margin-top: 10px;
    margin-left: 0; 
    padding: 0px;
    font-size: inherit;
    width: 220px;
    height: 60px; 
    border-radius: 10px;
  }
  body > div:nth-child(3) > div > div.modal.fade.show > div > div > div.providerMHead.modal-header > h4{
    font-size: 1.3rem;
  }
  .modal.fade.show > div > div > div.providerMHead.modal-header{
    padding: 10px 15px 10px 12%;
  }
  body > div:nth-child(3) > div > div.modal.fade.show > div{
    position: relative;
    left: 7px;
    top: 0;
    width: 92%;
    -webkit-transform: translate(0%, 0%) !important;
    -moz-transform: translate(0%, 0%) !important;
    -o-transform: translate(0%, 0%) !important;
    transform: translate(0%, 0%) !important;
  }
  .modal.fade.show > div > div > div.providerMBody.modal-body{
    padding: 0px;
  }
  body > div:nth-child(3) > div > div.modal.fade.show > div > div > div.providerMBody.modal-body > span > div > div > div > div > form > button{
    margin-top: -20px;
  }
  .modal.fade.show > div > div > div.providerMFooter.modal-footer{
    padding: 0px;
  }
  #root > div > footer > div > span{
    font-size: 8px;
    padding-bottom: 20px;
  }
  /*** HeaderAdmin ***/
  #root > div > div.HeaderAdmin > div > nav > button{
    margin-top: 8px;
    border-color: rgba(255, 255, 255, 0.5);    
  }
  #root > div > div.AccountHome > div > div > h1{
    font-size: 2rem;
  }
  #root > div > div.AccountHome > div > div > div:nth-child(3) > div > p{
    font-size: 13px;
  }
  div.AddFacility > div > div > h1.addRemoveFacilites{
    font-size: 1.75rem;
  }
  #root > div > div.AddFacility > div > div > div > div:nth-child(1) > h6,
  #root > div > div.AddFacility > div > div > div > div:nth-child(2) > h6{
    text-align: left;
    font-size: 15px;
  }
  #root > div > div.Profile > div > div > div > div > div > div > h3{
    font-size: 1.5rem
  }
  #root > div > div.Help > div > div > div > div > div > div > h1{
    font-size: 2rem;
  }
  .HeaderAdmin > div > nav > a{
    margin-left: -10px;
  }
  .HeaderAdmin .nav-link{
    padding: 0;
    padding-top: 10px;
  }
}

@media only screen and (orientation:portrait) and (min-width: 300px) {
  body{
    /*** iPhone & Mac & Android ****/
    background-position: center left;
    -webkit-background-size: cover cover;
    -moz-background-size: cover cover;
    -o-background-size: cover cover;
    background-size: cover cover;
  }
  #root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2){
    margin-left: 0px;
  }
  #root > div > div.Header > div > nav > button{
    margin-top: 8px;
    border-color: rgba(255, 255, 255, 0.3);
  }
  #root > div > div.Header > div > nav > a > img{
    margin-left: -10px;
  }
  #root > div > div.jumbotron > div > div:nth-child(1) > div > h1{
    font-size: 25px;
  }
  #root > div > div.jumbotron > div > div.text-center.row > div > p{
    font-size: 12px;
  }
  #root > div > div.jumbotron > div > div:nth-child(4) > div > div > h4{
    font-size: 1.3rem;
  }
  .Header .nav-item{
    padding-left: 5px;
  }
  #root > div > div.jumbotron > div > div > div > div{
    max-width: 100%;
  }
  #root > div > div.jumbotron > div > div:nth-child(4) > div > div > p > button.btn.btn-outline-danger{
    margin-top: 0;
    padding: 0;
    font-size: inherit;
    width: 220px;
    height: 60px; 
    border-radius: 10px;
  }
  #root > div > div.jumbotron > div > div:nth-child(4) > div > div > p > button.btn.btn-outline-primary{
    margin-top: 10px;
    margin-left: 0; 
    padding: 0px;
    font-size: inherit;
    width: 220px;
    height: 60px; 
    border-radius: 10px;
  }
  body > div:nth-child(3) > div > div.modal.fade.show > div > div > div.providerMHead.modal-header > h4{
    font-size: 1.3rem;
  }
  .modal.fade.show > div > div > div.providerMHead.modal-header{
    padding-left: 12%;
  }
  body > div:nth-child(3) > div > div.modal.fade.show > div{
    position: relative;
    left: 7px;
    top: 0;
    width: 92%;
    -webkit-transform: translate(0%, 0%) !important;
    -moz-transform: translate(0%, 0%) !important;
    -o-transform: translate(0%, 0%) !important;
    transform: translate(0%, 0%) !important;
  }
  .modal.fade.show > div > div > div.providerMBody.modal-body{
    padding: 0px;
  }
  #root > div > footer > div > span{
    font-size: 8px;
    padding-bottom: 20px;
  }
  /*** HeaderAdmin ***/
  #root > div > div.HeaderAdmin > div > nav > button{
    margin-top: 8px;
    border-color: rgba(255, 255, 255, 0.5);    
  }
  #root > div > div.AccountHome > div > div > h1{
    font-size: 2rem;
  }
  #root > div > div.AccountHome > div > div > div:nth-child(3) > div > p{
    font-size: 13px;
  }
  div.AddFacility > div > div > h1.addRemoveFacilites{
    font-size: 1.75rem;
  }
  #root > div > div.AddFacility > div > div > div > div:nth-child(1) > h6,
  #root > div > div.AddFacility > div > div > div > div:nth-child(2) > h6{
    text-align: left;
    font-size: 15px;
  }
  #root > div > div.Profile > div > div > div > div > div > div > h3{
    font-size: 1.5rem
  }
  #root > div > div.Help > div > div > div > div > div > div > h1{
    font-size: 2rem;
  }
  .HeaderAdmin > div > nav > a{
    margin-left: -10px;
  }
  .HeaderAdmin .nav-link{
    padding: 0;
    padding-top: 10px;
  }
}

@media only screen and (orientation:landscape) and (max-width: 850px){
  #root > div > div.jumbotron > div > div > div > div > p > button:nth-child(2){
    margin-left: 0;
  }
  #root > div > footer > div > span{
    padding-bottom: 20px;
  }
}


@media only screen and (orientation:portrait) and (min-width: 768px){
  #root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2){
    margin-left: 15px;
  }
  #root > div > div.AccountHome > div > div > div:nth-child(3) > div > button
  {
    font-size: 15px;
  }
}
/*fix margin-right*/
@media only screen and (orientation:landscape) and (max-width: 1200px){
  #root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2){
    margin-left: 0px;
  }
  #root > div > div.Header > div > nav > div
  {
    margin-left: 350px;
  }
  #root > div > div.AccountHome > div > div > div:nth-child(3) > div > button
  {
    font-size: 15px;
  }

}
@media only screen and (orientation:landscape) and (max-width: 1000px){
  #root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2){
    margin-left: 0px;
  }
  #root > div > div.Header > div > nav > div
  {
    margin-left: 250px;
  }
  #root > div > div.AccountHome > div > div > div:nth-child(3) > div > button
  {
    font-size: 15px;
  }
}
@media only screen and (orientation:landscape) and (max-width: 800px){
  #root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2){
    margin-left: 0px;
  }
  #root > div > div.Header > div > nav > div
  {
    margin-left: 150px;
  }
  #root > div > div.AccountHome > div > div > div:nth-child(3) > div > button
  {
    font-size: 15px;
  }
}@media only screen and (orientation:portrait) and (min-width: 400px){
  #root > div > div.jumbotron > div > div > div > div > div > button:nth-child(2){
    margin-left: 0px;
  }
  #root > div > div.Header > div > nav > div
  {
    margin-left: 50px;
  }
  #root > div > div.AccountHome > div > div > div:nth-child(3) > div > button
  {
    font-size: 15px;
  }
}


#otpSMSHome{
  margin-left: 18px;
}
#otpCallHome, #otpSMSWork, #otpCallWork{
  line-height: 1.5;
  margin-top: 8px;
  margin-left: 18px;
}

/*** reset the centeral text ***/
@media only screen and (orientation:portrait) and (min-width: 375px){
  #root > div > div.jumbotron > div > div:nth-child(1) > div.txtS {
    margin-left: 0 !important;
  }
}