#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > h1{
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    padding-bottom: inherit;
}

#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > h1 > span{
    display: inline;
    float: right;   
}

.facform {
    width: 75%;
    margin: 10px auto;
}

.facform .facSearch input {
    margin: 10px 10px 10px 100px;
    width: 292px;
}

.facform .emailFields label {
    width: 160px;
}

.facform .facList {
    height: 100px;
    overflow-y: scroll;
    font-size: .9em;
    margin: 10px 10px 10px 100px;
}

div.facform button {
    border: none;
    background-color: none;
    width: 192px;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.01em;
    margin-left: 180px;
}

/* Maybe later */
#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(2) > div > button.btn.btn-info.btn-lg {
    float: right;
    margin-left: 15px;
    width: 18%;
}

/* Yes */
#facilitySelect {
    float: right;
    width: 18%;
}

.facform div.error {
    color: #ff0000;
    font-weight: bold;
    margin-left: 120px;
    font-size: .9em;    
}

input[type="radio"] {
-ms-transform: scale(1.5); /* IE 9 */
-webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
transform: scale(1.5);
}

input[type="checkbox"] {
-ms-transform: scale(1.5); /* IE 9 */
-webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
transform: scale(1.5);
}

#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > div:nth-child(4) > div {
    float: left;
    display: inline;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1.25rem;
    background-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
    /*border-color: black;*/
    /*margin-left: 8%;*/ 
}

#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > div:nth-child(5) > div {
    float: left;
    display: inline;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1.25rem;
    background-color: transparent;
    margin-left: 8%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
    /*border-color: black;*/
}

#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > div:nth-child(6) > div {
    float: left;
    display: inline;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1.25rem;
    background-color: transparent;
    margin-left: 8%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
    /*border-color: black;*/
}

#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > div:nth-child(4) > div > span,
#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > div:nth-child(5) > div > span,
#root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > div:nth-child(6) > div > span
{
    padding: 10px 15px;
}

/*input[type=radio]:checked + div.facRow {
  color: #0DFF92;
  background-color: white;
}

input[type=radio]:checked + div.facRow::before{
  background: red;
}*/

input[type=checkbox] {
    position: fixed;
    float: right;
    cursor: pointer;
}

.facRow input[type=radio]:checked + label{
  color: #0275d8;
  font-weight: bold;
}

/*** 
    Images as Radio buttons
    https://gist.github.com/rcotrina94/7828886 
  ***/
  .cc-selector input[type=radio] {
    position:absolute;
    cursor: pointer;
    z-index:999;
  }

  .facRow .Cedars-Sinai,
  .facRow label[for="fac1"]{
    /*background-image:url(https://www.mycslink.org/images/MyCSLink-White_345x110.png);*/
    /*background-image:url(https://patients.mycslink.org/mycslink/en-US/images/logo.png);*/
    background-image: url(../images/csLinkLogo.png);
      background-position-x: center;
      background-position-y: center;
  }

  .facRow .California{
    /*background-image:url(https://www.californiarehabinstitute.com/Resources/img/CRI-logo.png);*/
    background-image: url(../images/CRI-logo.png);
      background-position-x: center;
      background-position-y: center;
  }

  .facRow .MyUCSDChart,
  .facRow .Diego,
  .facRow .UCSD,
  .facRow label[for="fac2"]
  {
    /*background-image:url(https://www.californiarehabinstitute.com/Resources/img/CRI-logo.png);*/
    background-image: url(../images/ucsd_health.png);
      background-position-x: center;
      background-position-y: center;
      background-size: 100% 50%;
  }

  .facRow .CU .UC,
  .facRow label[for="fac3"],
  .facRow label[for="fac981789ba-9ee4-11eb-9b7b-06e5c17ec1ae"]
 {
    /*background-image:url(https://www.californiarehabinstitute.com/Resources/img/CRI-logo.png);*/
    background-image: url(../images/myHealth.png);
      background-position-x: center;
      background-position-y: center;
  }

  .facRow .UCSFHealth,
  .facRow .UCSF,
  .facRow label[for="fac4"]{
    background-image: url(../images/UCSF_Health_Logo.png);
      background-position-x: center;
      background-position-y: center;
  }

  #root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(1) > div > p{
    margin-bottom: 20px;
  }

  #root > div > div:nth-child(2) > div.slectFacForm.jumbotron > div > div:nth-child(2) > div{
    margin-top: 10px;
  }

  /*.cc-selector input:active +.drinkcard-cc{opacity: .9;}
  .cc-selector input:checked +.drinkcard-cc{
      -webkit-filter: none;
         -moz-filter: none;
              filter: none;
  }*/
  .drinkcard-cc{
      cursor:pointer;
      background-size:contain;
      background-repeat:no-repeat;
      display:inline-block;
      width:130px;
      height:130px;   
      /*-webkit-transition: all 100ms ease-in;
         -moz-transition: all 100ms ease-in;
              transition: all 100ms ease-in;
      -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
         -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
              filter: brightness(1.8) grayscale(1) opacity(.7);*/
  }
  /*.drinkcard-cc:hover{
      -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
         -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
              filter: brightness(1.2) grayscale(.5) opacity(.9);       
  }*/
  /*********************************************************************/

