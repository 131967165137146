/*.rootPage .Header{
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}*/

.rootPage .Privacy{
  /*background-image: url("../../images/pexels-libray.jpeg");*/
  min-height: 80%;
  margin-bottom: -1rem !important;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}

.rootPage .Terms{
  min-height: 80%;
  margin-bottom: -1rem !important;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}

.Privacy p,
.Terms p{
  line-height: 1.6;
}

.Terms ul.d {
  list-style-type: disc;
}

.Terms ul.c {
  list-style-type: circle;
}
