.mfa {
    width: 75%;
    margin: 10px auto;
}

.formRow {
    margin: 6px;
    position: relative;
    width: 100%;
    max-width: 700px;
}

.mfa .radioRow {
    margin: 6px;
}

.mfa .submitBar {
    margin: 20px auto;
    width: 200px;

}

.mfa .err {
    color: #ff0000;
    font-weight: bold;
    
}

.mfa .phoneOptions {
    margin-left: 32px;
}

button.yellowUI {
        border: none;
        background-color: none;
        width: 192px;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-size: 1.01em;
        margin-left: 180px;
}

button.patientsui {
    border: none;
    background-color: none;
    width: 192px;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.01em;
    margin-left: 180px;
    color: #024a68;
}

button.patientsui:hover  {
    color: #63afd0;
}
