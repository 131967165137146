html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
 font-family: 'Open Sans', sans-serif;
 font-size: .95em;
  height: 100%;
  width: 100%;
}

div#root {
  display: flex;
  height: 100%;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

div#root > div {
  width: 100%;
}

.content {
  flex: 1; 
  width: 100%;
}

.App {
  flex-flow: row nowrap;
  justify-content: center;
  min-height: calc(100vh - 160px);
}
