div.Provider {
 flex: 0 1 auto;
    font-size: large;
    margin-top: 20px;
    min-width: 350px; 
    max-width: 800px;
    margin: 20px auto 0 auto;
    }

div.Provider .PatientOptions {
    display: flex;
    flex-flow: row nowrap;
}

div.Provider .PatientOption {
    font-size: .8em;
    width: 400px;
}

div.Provider .optionLabel {
    display: inline-block;
    width: 300px;
    margin: 4px;
}

